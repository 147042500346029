var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('BaseTitlePage',{scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('BaseButton',{directives:[{name:"permission",rawName:"v-permission",value:({ roles: ['A1'], operator: '===' }),expression:"{ roles: ['A1'], operator: '===' }"}],attrs:{"id":"btn-create","name":"btn-create","color":"secondary","title":"Novo cadastro","icon":"fa-solid fa-plus"},on:{"click":_vm.create}})]},proxy:true}])}),_c('BaseSearchPage',{attrs:{"title":"Consultar e filtrar por unidade"},scopedSlots:_vm._u([{key:"form",fn:function(){return [_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.search.apply(null, arguments)}}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","xl":"4","lg":"4","md":"4","sm":"10"}},[_c('v-text-field',{attrs:{"id":"filter","name":"filter","label":"Nome","dense":"","clearable":"","outlined":"","hide-details":""},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1),_c('v-col',{attrs:{"cols":"12","xl":"2","lg":"2","md":"2","sm":"2"}},[_c('BaseButton',{attrs:{"id":"btn-search","name":"btn-search","type":"submit","height":"40","color":"primary","title":"Filtrar","icon":"fa-solid fa-magnifying-glass"}})],1)],1)],1)]},proxy:true}])}),_c('BaseTableList',{attrs:{"title":"Listagem de unidades"},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('v-data-table',{attrs:{"dense":"","disable-sort":"","item-key":"id","headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalRecords,"items-per-page":10,"footer-props":{
          itemsPerPageOptions: [10, 20, 50, 100],
          showFirstLastPage: true,
          firstIcon: 'first_page',
          lastIcon: 'last_page',
          prevIcon: 'chevron_left',
          nextIcon: 'chevron_right'
        }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:`item.action`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({attrs:{"color":"success","small":""},on:{"click":function($event){return _vm.edit(item)}}},on),[_vm._v(" fa-solid fa-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Visualizar")])])]}}],null,true)})]},proxy:true}])}),(_vm.dialog)?_c('Edit',{attrs:{"title":_vm.modalTitle,"dialog":_vm.dialog,"edit":_vm.editMode,"selectedItem":_vm.selectedItem},on:{"closeModal":_vm.closeModal}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }