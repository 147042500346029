<template>
  <v-container>
    <BaseTitlePage>
      <template v-slot:button>
        <BaseButton
          id="btn-create"
          name="btn-create"
          color="secondary"
          title="Novo cadastro"
          icon="fa-solid fa-plus"
          @click="create"
          v-permission="{ roles: ['A1'], operator: '===' }"
        />
      </template>
    </BaseTitlePage>

    <BaseSearchPage title="Consultar e filtrar por unidade">
      <template v-slot:form>
        <v-form @submit.prevent="search" ref="form" lazy-validation>
          <v-row dense>
            <v-col cols="12" xl="4" lg="4" md="4" sm="10">
              <v-text-field
                id="filter"
                name="filter"
                label="Nome"
                dense
                clearable
                outlined
                hide-details
                v-model="filter"
              />
            </v-col>
            <v-col cols="12" xl="2" lg="2" md="2" sm="2">
              <BaseButton
                id="btn-search"
                name="btn-search"
                type="submit"
                height="40"
                color="primary"
                title="Filtrar"
                icon="fa-solid fa-magnifying-glass"
              />
            </v-col>
          </v-row>
        </v-form>
      </template>
    </BaseSearchPage>

    <BaseTableList title="Listagem de unidades">
      <template v-slot:table>
        <v-data-table
          dense
          disable-sort
          item-key="id"
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="totalRecords"
          :items-per-page="10"
          :footer-props="{
            itemsPerPageOptions: [10, 20, 50, 100],
            showFirstLastPage: true,
            firstIcon: 'first_page',
            lastIcon: 'last_page',
            prevIcon: 'chevron_left',
            nextIcon: 'chevron_right'
          }"
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="success" small @click="edit(item)">
                  fa-solid fa-pencil
                </v-icon>
              </template>
              <span>Visualizar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </template>
    </BaseTableList>

    <Edit
      v-if="dialog"
      :title="modalTitle"
      :dialog="dialog"
      :edit="editMode"
      :selectedItem="selectedItem"
      @closeModal="closeModal"
    />
  </v-container>
</template>

<script>
import { BusinessUnitService } from '@/services/api/businessUnit';

export default {
  components: {
    Edit: () => import('@/views/AccessControl/BusinessUnit/Edit')
  },

  data: () => ({
    filter: '',
    options: {},
    dialog: false,
    selectedItem: {},
    modalTitle: '',
    editMode: false,
    headers: [
      { text: 'Nome', value: 'name' },
      { text: '', value: 'action', width: '10%' }
    ],
    items: [],
    totalRecords: 0
  }),

  methods: {
    async search() {
      try {
        const { page, itemsPerPage } = this.options;
        const businessUnitService = new BusinessUnitService();
        const { status, data } = await businessUnitService.getBusinessUnit({
          page,
          take: itemsPerPage,
          filter: this.filter
        });

        if (status === 200) {
          this.items = data.results;
          this.totalRecords = data.totalRecords;
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    create() {
      this.selectedItem = {};
      this.editMode = false;
      this.modalTitle = 'Adicionar unidade';
      this.dialog = !this.dialog;
    },

    edit(item) {
      this.selectedItem = Object.assign({}, item);
      this.editMode = true;
      this.modalTitle = 'Visualizar unidade';
      this.dialog = !this.dialog;
    },

    closeModal(create) {
      this.dialog = false;

      if (create) this.search();
    }
  },

  watch: {
    options: {
      handler() {
        this.search();
      },
      deep: true
    }
  }
};
</script>
